/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --white-color: #fff;
  --grey-color: #707070;
  --grey-color-light: #aaa;
  --light-blue-color: #E7E7FF;
  --hover-text-color: #E541ED;
}

body {
  /* background-color: #f3f4f6; */
  transition: all 0.5s ease;
}

body.dark {
  background-color: #333;
}

body.dark {
  --white-color: #333;
  --blue-color: #fff;
  --grey-color: #f2f2f2;
  --grey-color-light: #aaa;
}

/* sidebar */
.sidebar {
  background-color: var(--white-color);
  width: 367px;
  height: 1944px;
  ;
  height: 300vh;
  padding: 40px 20px;
  overflow-y: scroll;
  box-shadow: 0 0 1px var(--grey-color-light);
  transition: all 0.5s ease;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.menu_content {
  position: relative;
}

.menu_title {
  margin: 5px 0;
  padding: 0 20px;
  font-size: 18px;
}

.menu_title::before {
  color: var(--hover-text-color);
  white-space: nowrap;
}

.sidebar.close .menu_title::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 18px;
  border-radius: 12px;
  background: var(--grey-color-light);
}

.menu_items {
  padding: 0;
  list-style: none;
}

.navlink_icon:hover {
  background: var(--light-blue-color);
}

.sidebar .nav_link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 49px;
  padding: 15px 15px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 18px;
  color: var(--grey-color);
  white-space: nowrap;
}

.sidebar.close .navlink {
  display: none;
}

.nav_link:hover {

  color: var(--hover-text-color);
  background: var(--light-blue-color);
}

.sidebar.close .nav_link:hover {
  background: var(--white-color);
}

.submenu_item {
  cursor: pointer;
}

.submenu {
  display: none;
}

.submenu_item .arrow-left {
  position: absolute;
  right: 10px;
  display: inline-block;
  margin-right: auto;
}

.sidebar.close .submenu {
  display: none;
}

.show_submenu~.submenu {
  display: block;
}

.show_submenu .arrow-left {
  transform: rotate(90deg);
}

.submenu .sublink {
  padding: 15px 15px 15px 52px;
}

@media screen and (max-width: 768px) {
  #sidebarOpen {
    font-size: 25px;
    display: block;
    margin-right: 10px;
    cursor: pointer;
    color: var(--grey-color);
  }

  .sidebar.close {
    left: -100%;
  }

  .search_bar {
    display: none;
  }

  .sidebar.close .bottom_content {
    left: -100%;
  }
}

/*--------------Body Part------------------*/

.second_container {
  padding: 40px;
  width: 100%;
}

.order_id {
  font-weight: 900;
  font-size: 25px;
  line-height: 28px;
}

.order_text {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.button_color button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 190px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.assignment_help_text {
  font-weight: 400 !important;
}

.assignment_content {
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.time_stamp_heading {
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  color: #5956E9;
}

.date_and_time {
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  color: black;
}

.border {
  width: 42px;
  height: 42px;
  border-radius: 1px solid #FCB2FF;
  background-color: #D2D1FF;
  border-radius: 5px;
}

.border p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.border .timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
}

.deadline {
  font-size: 18px;
  color: #E541ED;
}

.student_corner_text {
  font-size: 21px;
  color: #E541ED;
  font-weight: 500;
}

.edit_button_text {
  font-size: 20px;
}

.button_edit button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
  font-size: 21px;
}

.drop_down_arrow {
  font-size: 40px;
}

.cancel_button button {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.submit_button button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.request_div {
  height: 79px;
  background-color: #DDDDFF;
}

#button_addon2 {
  background: linear-gradient(to right, #5956E9, #E541ED);
  color: white;
  border: none;
}


.Skip_categories {
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  color: #E541ED;
}

.reason {
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  color: #5956E9;
}

.names {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.information {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.change {
  width: 136px;
  height: 43px;
  background: linear-gradient(#7471FF, #124CA2);
}

.quesion_color {
  background-color: #DDDDFF;
  height: 79px;
}

.question_text {
  width: 420px;
  height: 47px;
  border-radius: 10px;
  background-color: #fff;

}

.question_text p {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2C2C2C;
}

.question_text img {
  width: 22px;
  height: 23.2px;
  border: 2px solid #E541ED;
  border: none;
}

.cancel_button button {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.submit_button button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.question_right {
  width: 230px;
  height: 47px;
  border-radius: 10px;
  background-color: #fff;

}

.tutor_question {
  color: #E541ED;
  font-size: 21px;
}

.working_button button {
  background: linear-gradient(#1EF644, #0B9223);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.not_working_button button {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.Suspension_corner {
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  color: #E541ED;
}

.edit_button {
  width: 140px;
  height: 47px;
  border-radius: 10px;
  background: linear-gradient(to right, #5956E9, #E541ED);
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  border: none;
}

.Suspension_list {
  font-weight: 600;
  font-size: 21px;
  line-height: 8px;
  color: #5956E9;
}

.sr_no {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.name {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;

}

.number {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #2C2C2C;
}

.number img {
  height: 28.48px;
  width: 27px;
  margin-left: 20px;
}

.activate {
  background: linear-gradient(#5BF978, #30A745);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.days {
  color: #2C2C2C;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.quesion_color {
  background-color: #DDDDFF;
}

.question_text {
  width: 269px;
  height: 47px;
  border-radius: 10px;
  background-color: #fff;

}

.question_text p {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2C2C2C;
}

.question_text img {
  width: 22px;
  height: 23.2px;
  border: 2px solid #E541ED;
  border: none;
}

.cancel_button button {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.submit_button button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.close_question {
  width: 340px;
  height: 47px;
  border-radius: 10px;
  background-color: #fff;
}



/*--------------- Newely Added Css-----------------*/

.Back_button {
  background-color: white;
  border: #5956E9 2px solid !important;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  color: #5956E9;
  font-size: 18px;
}

.exam_config_content {
  border: 1px solid rgb(181, 179, 179);
  border-radius: 5px;
}

#subject_choice {
  background-color: rgb(231, 230, 230);
  color: #000000;
  width: 230px;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px;
}

.subject {
  background-color: #e541ed;
  padding: 10px 20px 10px 20px;
  border: 0px !important;
  border-radius: 5px;
  margin-left: 10%;
  color: #fff;
}

.edit {
  background-color: #5956E9;
  padding: 10px 50px 10px 50px;
  border: 0px !important;
  border-radius: 5px;
  margin-left: 10%;
  margin-right: 5%;
  color: #ffffff;
}

.delete {
  background-color: #B71515;
  padding: 10px 50px 10px 50px;
  border: 0px !important;
  border-radius: 5px;
  color: #ffffff;
}

.add_button {
  width: 140px;
  height: 47px;
  border-radius: 10px;
  background: #E541ED;
  color: white;
  /* font-weight: 600; */
  font-size: 17px;
  line-height: 28px;
  border: none;
}

.delete_button {
  width: 140px;
  height: 47px;
  border-radius: 10px;
  background: #B71515;
  color: white;
  /* font-weight: 600; */
  font-size: 17px;
  line-height: 28px;
  border: none;
}

.submit_profile_button {
  background-color: #5956E9;
  padding: 10px 50px 10px 50px;
  border: 0px !important;
  border-radius: 5px;
  margin-left: 10%;
  margin-right: 5%;
  color: #ffffff;
}

.add_subject_button {
  width: 210px;
  height: 47px;
  border-radius: 10px;
  background: #E541ED;
  color: white;
  font-size: 18px;
  line-height: 28px;
  border: none;
}

.pass {
  background-color: #30A745;
  padding: 10px 20px 10px 20px;
  border: 0px !important;
  border-radius: 5px;
  margin-left: 10%;
  width: 100px;
  color: #fff;
}

.fail {
  background-color: #B71515;
  padding: 10px 20px 10px 20px;
  border: 0px !important;
  border-radius: 5px;
  margin-left: 10%;
  width: 100px;
  color: #fff;
}
.add_subject {
  margin-top: -2px;
  margin-right: 6px;
}

.h_200 {
  height: 800px;
}

.bank_details_name {
  width: 50%;
  cursor: pointer;
}

.transaction_box {
  border: 1px solid #CCCCCC;
  padding: 10px;
  border-radius: 6px;
}