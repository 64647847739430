.second_container {
  padding: 40px;
  width: 100%;
}

.order_id {
  font-weight: 900;
  font-size: 25px;
  line-height: 28px;
}

.order_text {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.button_color button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  /* width: 190px; */
  width: 146px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.assignment_help_text {
  font-weight: 400 !important;
}

.assignment_content {
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.time_stamp_heading {
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  color: #5956E9;
}

.date_and_time {
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  color: black;
}

.border {
  width: 42px;
  height: 42px;
  border-radius: 1px solid #FCB2FF;
  background-color: #D2D1FF;
  border-radius: 5px;
}

.border p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.border .timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
}

.deadline {
  font-size: 18px;
  color: #E541ED;
}

.student_corner_text {
  font-size: 21px;
  color: #E541ED;
  font-weight: 500;
}

.edit_button_text {
  font-size: 20px;
}

.button_edit button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 100px;
  height: 43px;
  border-radius: 10px;
  color: white;
  border: none;
  font-size: 20px;
}

.edit_code button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 90px;
  height: 40px;
  border-radius: 10px;
  color: white;
  border: none;
  font-size: 14px;
}

.drop_down_arrow {
  font-size: 40px;
}

/* .cancel_button button {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
} */

.submit_button button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.request_div {
  height: 79px;
  background-color: #DDDDFF;
}

#button_addon2 {
  background: linear-gradient(to right, #5956E9, #E541ED);
  color: white;
  border: none;
}


.Skip_categories {
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  color: #E541ED;
}

.reason {
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  color: #5956E9;
}

.names {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.information {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.change {
  width: 136px;
  height: 43px;
  background: linear-gradient(#7471FF, #124CA2);
}

.quesion_color {
  background-color: #DDDDFF;
  height: 79px;
}

.question_text {
  width: 420px;
  height: 47px;
  border-radius: 10px;
  background-color: #fff;

}

.question_text p {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2C2C2C;
}

.question_text img {
  width: 22px;
  height: 23.2px;
  border: 2px solid #E541ED;
  border: none;
}

/* .cancel_button button {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
} */

.submit_button button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.question_right {
  width: 230px;
  height: 47px;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;

}

.tutor_question {
  color: #E541ED;
  font-size: 21px;
}

.working_button button {
  background: linear-gradient(#1EF644, #0B9223);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.not_working_button button {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.Suspension_corner {
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  color: #E541ED;
}

.edit_button {
  width: 140px;
  height: 47px;
  border-radius: 10px;
  background: linear-gradient(to right, #5956E9, #E541ED);
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  border: none;
}

.Suspension_list {
  font-weight: 600;
  font-size: 21px;
  line-height: 8px;
  color: #5956E9;
}

/* th {
  background-color: #f3f2f2 !important;
  border: 4px solid #fff !important;
}

td {
  background-color: #f3f2f2 !important;
  border: 4px solid #fff !important;

} */

.sr_no {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.name {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;

}

.number {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #2C2C2C;
}

.number img {
  height: 28.48px;
  width: 27px;
  margin-left: 20px;
}

.activate {
  background: linear-gradient(#5BF978, #30A745);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  width: 100%;
  border: none;
}

.rejected {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  width: 100%;
  border: none;
}

.days {
  color: #2C2C2C;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.quesion_color {
  background-color: #DDDDFF;
}

.question_text {
  width: 269px;
  height: 47px;
  border-radius: 10px;
  background-color: #fff;

}

.question_text p {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2C2C2C;
}

.question_text img {
  width: 22px;
  height: 23.2px;
  border: 2px solid #E541ED;
  border: none;
}

.cancel_button button {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.delete_button_code {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  width: 90px;
  height: 40px;
  border-radius: 10px;
  color: white;
  border: none;
  font-size: 14px;
}

.submit_button button {
  background: linear-gradient(to right, #5956E9, #E541ED);
  width: 140px;
  height: 47px;
  border-radius: 10px;
  color: white;
  border: none;
}

.close_question {
  width: 340px;
  height: 47px;
  border-radius: 10px;
  background-color: #fff;
}


.sessionpay {
  width: 20%;
}

.session_pay_button {
  padding: 4px 30px;
  color: #fff;
  background-color: #E541ED;
  border: none;

}

.svg_plus_icon {
  border: 2px solid;
  border-radius: 14px;
  font-size: 22px;
  color: #5956e9;
  padding: 3px;
}

.duration_session_time {
  border: 1px solid #cbc3c3;
  width: 50%;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: 500;
}

.plandoll {
  border: 1px solid #d0d3d5;
  padding: 6px 14px;
  background: #e9ecef;
  border-radius: 4px;
}

.plandoll_input {
  margin-left: -5px;
}

.h_200 {
  height: 800px;
}

.delete_icon {
  margin-top: -5px;
  margin-right: 4px;
}


.selectedOption {
  border: 3px solid #d244ec;
}

.form_check_input:checked {
  background-color: #E541ED;
  border-color: #E541ED;
}


td p img {
  width: 100px;
}

.highlighted {
  border: 2px solid #794aed;
  border-radius: 11px;
}


td.story-img img {
  width: 50%;
}

td.story-img {
  width: 13% !important;
}

td.social-img img {
  width: 14%;
}

td.social-img {
  width: 27%;
}

td.cms-table-box {
  width: 25%;
}