.disabled-button {
    // background: linear-gradient(#1EF644, #0B9223);
    background-color: #6c757d;
    border: none;
    cursor: not-allowed !important;
    color: #fff;
    padding: 6px;
    border-radius: 4px;
}

.gift-send {
    background: linear-gradient(#1EF644, #0B9223);
    border: none;
    cursor: pointer;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
}

// Student List
.record-details {
    display: flex;
    align-items: center;
}

.record-label {
    margin-right: 10px;
}

.record-value {
    font-weight: bold;
}