p.dashboard-title {
    font-size: 1rem;
    margin-bottom: 0px;
}

.card {
    font-size: 14px;
}


.page-link {
    color: #d044ed !important;
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: #fff !important;
    background-color: #d044ed !important;
    border-color: #d044ed !important;
}