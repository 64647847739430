.app {
  /* background: #F5F5F5; */
  display: flex;
  flex-direction: row;
}
.content {
  flex-grow: 2;
  margin-left: 0px;
}
/* .loggedIn{
  margin-left: 280px;
} */