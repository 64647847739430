.border {
    width: 44px;
    height: 44px;
    border-radius: 1px solid #FCB2FF;
    background-color: #D2D1FF;
    border-radius: 5px;
}

.border p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 0px;
    margin-top: 5px;
}

.border .timer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 11px;
    line-height: 0px;
}

.answer-pdf {
    border: 1px solid #e984e6;
    padding: 2px;
}

.answer-pdf-box {
    border: none;
    color: #e025ef;
    font-weight: 600;
    float: inline-end;
}