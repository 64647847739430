.sidebar {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: thin;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: 0;
  z-index: 99;
}

.menu {
  list-style: none;
  border-right: 1px solid #c5c5c5;
  height: auto;
}

.border {
  border-bottom: 1px solid #c5c5c5;
  width: 100%;
  border-right: 1px solid #c5c5c5;
}