th.total-amount {
  background-color: #e7e7ff !important;
  vertical-align: middle;
}

th.assignment-title {
  vertical-align: middle;
  background-color: #ffe7e7 !important;
  width: 60%;
}

th.assignment-title-student {
  vertical-align: middle;
}

th.assignment-title-tutor {
  vertical-align: middle;
  background-color: #fedfff !important;
}

td.assignment-title-tutor {
  vertical-align: middle;
  background-color: #fedfff !important;
}

th.table-data {
  vertical-align: middle;
  font-weight: 500;
  width: 60%;
}

.studentamount {
  color: #1cb437 !important;
}

.tutoramount {
  color: #b71515 !important;
}

.font-weight-bold {
  font-weight: 700;
}

button.action-button.btn.btn-secondary {
  background-color: #e541ed;
  // border: none;
  // padding: 8px 22px;
  border: none;
  padding: 4px 9px;
  // padding: 2px 26px;
  font-size: 13px;
}

button.action-button-danger.mx-2.btn.btn-secondary {
  background: linear-gradient(to right, #E96856, #9E0E0E);
  border: none;
  padding: 4px 9px;
  font-size: 13px;
}

.delete_ques {
  background: linear-gradient(to right, #e96856, #9e0e0e);
  border: none;
  padding: 7px 7px;
  font-size: 13px;
}

.invoice {
  border: none;
  padding: 4px 9px;
  font-size: 13px;
  margin-left: 4px;
}

button.action-button.add-from-new.btn.btn-secondary {
  background-color: #e541ed;
  border: none;
  padding: 8px 20px;
  font-size: 15px;
}

button.action-button-paid.btn.btn-secondary {
  background-color: green;
  color: white;
  border: none;
  padding: 8px 38px;
  border-radius: 7px;
}

.border_none_icon {
  border: none;
}

.search_input_icon.input-group {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  // padding: 3px;
  padding: 0px;
}

.input-group-text.wallte {
  background-color: rgb(89, 86, 233);
  // padding: 10px;
  // color: white;
  padding: 7px;
  color: white;
  margin-top: 2px;
  margin-right: 3px;
}

.date_padding {
  padding: 10px;
}

.Extra_padding {
  padding: 0px 13px;
}

.form-control:focus {
  box-shadow: none;
}

.font-small-head {
  font-size: 0.8rem;
}

button.text-success {
  width: 100%;
  margin-bottom: 4px;
  border: 1px solid green;
  padding: 6px;
}

button.text-danger {
  width: 100%;
  padding: 6px;
  border: 1px solid red;
}

.table-responsive {
  overflow-x: auto;
}