.navbar {
  display: flex;
  padding: 22px;
  justify-content: flex-end;
}

.box {
  width: 149px;
  border: 1px solid #CCCCCC;
  background: white;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
}

.dropdownToggle {
  /* Styles for the dropdown toggle button */
  background: none;
  border: none;
  cursor: pointer;
}

.dropdownContent {
  /* Styles for the dropdown content */
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(3, 4, 7, 0.2);
  z-index: 1;
  margin-top: 205px;
  padding: 22px;
  border-radius: 13px;
}


.list {
  list-style: none;
  padding-bottom: 9px;
  cursor: pointer;
  color: #000;
}

.list:hover {
  color: #E541ED;
}

.dropdownLink {
  text-decoration: none;
}