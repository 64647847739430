.menu {
    list-style: none;
}

.sidebar_menu:hover {
    background: #E7E7FF;
}

.sidebar_menu:hover a,
.sidebar_menu:hover button {
    color: #E541ED !important;
}

.submenu i {
    color: #C3C0CE;
}

.submenu:hover i {
    color: #7455FC !important;
}

.submenu.active i {
    color: #7455FC !important;
}

.sidebar_menu.active a {
    color: #E541ED !important;
}

.sidebar_menu.active {
    background: #E7E7FF;
}