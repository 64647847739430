.mx-auto.col-lg-7 {
    background-color: #ffff;
    padding: 40px;
    margin-top: 10%;
    border: 1px solid #e1dada;
}

.login-button {
    background: linear-gradient(to right, #5956E9, #E541ED);
    border: none;
}